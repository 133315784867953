<template>
  <v-container v-if="isConfirm" fill-height height="100vh">
    <v-layout column fill-height wrap height="100vh">
      <v-container
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto pa-1"
      >
        <v-row v-if="agencyPhoneNumber">
          <v-col class="ma-0 pa-0">
            <TelButton :agencyTel="agencyPhoneNumber" buttonClass="float-right" />
          </v-col>
        </v-row>
        <v-card-title class="font-weight-bold pb-2">
          {{ $t('title.contractor.auth') }}
        </v-card-title>
        <v-row class="pl-4">
          <p>
            {{ sendAuthMessage }}
          </p>
        </v-row>
        <v-container>
          <ValidationForms ref="forms">
            <v-row class="mb-2">{{ $t('label.authCode') }}</v-row>
            <v-row style="width: 90%; max-width: 350px;" class="mx-auto">
              <ValidationForm :rules="authRules">
                <v-text-field
                  v-model="authCode"
                  type="text"
                  :label="$t('label.authCode')"
                  dense
                  hide-details
                  @keyup.enter="onConvertToHalfWidth()"
                  @blur="onConvertToHalfWidth()"
                  @compositionend="onConvertToHalfWidth()"
                ></v-text-field>
              </ValidationForm>
            </v-row>
          </ValidationForms>
          <template v-if="isError">
            <v-row style="width: 90%; max-width: 350px;" class="mx-auto">
              <p class="font-weight-bold" style="color:red">
                {{ $t('error.selfCheckError') }}
              </p>
            </v-row>
          </template>
          <v-row class="my-2">
            <div class="text-center my-auto">
              <input
                type="checkbox"
                id="continuationFlg"
                value="continuationFlg"
                v-model="continuationFlg"
              />
              <label class="ml-1" for="continuationFlg">{{
                $t('checkbox.continuationFlg')
              }}</label>
            </div>
            <p
              v-for="(message, index) in splitMessageLine(
                $t('description.continuationFlg')
              )"
              :key="index"
              class="ml-2 my-0"
              style="font-size:14px"
            >
              {{ message }}
            </p>
          </v-row>
        </v-container>
        <v-row class="my-2 justify-center">
          <v-col style="text-align:center">
            <v-btn
              rounded
              class="font-weight-black mx-auto"
              align="center"
              width="100%"
              max-width="120px"
              color="back"
              style="font-size:20px"
              dark
              @click="backlAuth"
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col style="text-align:center">
            <v-btn
              rounded
              class="font-weight-black mx-auto"
              align="center"
              width="100%"
              max-width="120px"
              color="next"
              style="font-size:20px"
              :dark="!(isConnecting || isAgency)"
              :disabled="isConnecting || isAgency"
              @click="resendAuth"
              >{{ $t('button.resend') }}</v-btn
            >
          </v-col>
          <v-col style="text-align:center">
            <v-btn
              rounded
              class="font-weight-black mx-auto"
              align="center"
              width="100%"
              max-width="120px"
              color="next"
              style="font-size:20px"
              :dark="!isConnecting"
              :disabled="isConnecting"
              @click="onAuth"
              >{{ $t('button.confirm') }}</v-btn
            >
          </v-col>
        </v-row>
        <template v-if="isAgency">
          <v-card-title class="font-weight-bold pb-2">
            {{ $t('label.contactInformation') }}
          </v-card-title>
          <v-row
            class="mt-2"
            v-for="agencyRecord in agencyRecords"
            :key="agencyRecord.key"
          >
            <v-col class="my-0 py-0">
              <p class="font-weight-bold mt-1">
                {{ $t(`header.agencyInfoErrorTable.${agencyRecord.key}`) }}
              </p>
            </v-col>
            <v-col class="my-0 py-0">
              <p class="mt-1">{{ agencyRecord.value }}</p>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <Dialog
        :maxWidth="320"
        :showDialog="showResndAuthCodeSuccessDialog"
        :title="null"
        :text="sendAuthCompleteMessage"
        :subText="null"
        :negativeButtonTitle="null"
        :positiveButtonTitle="$t('button.close')"
        :onClickNegativeButton="null"
        :onClickPositiveButton="closeResndAuthCodeSuccessDialog"
      />
      <Dialog
        :maxWidth="320"
        :showDialog="showResndAuthCodeFailureDialog"
        :title="null"
        :text="resndAuthCodeFailureMessage"
        :subText="null"
        :negativeButtonTitle="null"
        :positiveButtonTitle="$t('button.close')"
        :onClickNegativeButton="null"
        :onClickPositiveButton="closeResndAuthCodeFailureDialog"
      />
    </v-layout>
  </v-container>
</template>

<script>
import {
  AuthCodeExpiryTime,
  AuthCodeLength,
  ContractStatus,
  completedStepNumber,
  HttpStatus,
  ReverseSenderFlg,
} from '@/lib/const';
import { splitMessageLine } from '@/lib/commonUtil';
import TelButton from '@/components/molecules/contractor/TelButton';
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import Dialog from '@/components/organisms/contractor/Dialog.vue';
import { getStatus } from '@/apis/contractor/contracts';
import { mapGetters, mapActions } from 'vuex';
import {
  certificationsAuth,
  resendAuthCode,
} from '@/apis/contractor/certifications';
import { getAgencies } from '@/apis/contractor/agencies';

export default {
  name: 'Auth',
  components: {
    splitMessageLine,
    TelButton,
    ValidationForms,
    ValidationForm,
    Dialog,
  },
  data() {
    return {
      // 認証コード
      authCode: null,
      // 認証エラーフラグ
      isError: false,
      // 本人確認済みフラグ
      isConfirm: false,
      // API通信中判定
      isConnecting: false,

      showAuthErrorDialog: false,
      showResndAuthCodeSuccessDialog: false,
      showResndAuthCodeFailureDialog: false,

      // 認証コードバリデーションルール
      authRules: `required|numeric|length:${AuthCodeLength}`,

      // 再送エラーメッセージ
      resndAuthCodeFailureMessage: '',
      // 認証コード送信メッセージ
      sendAuthMessage: '',
      // 認証コード送信完了メッセージ
      sendAuthCompleteMessage: '',

      // 代理店表示フラグ
      isAgency: false,
      // 代理店情報
      agencyRecords: [],
      // 代理店電話番号
      agencyPhoneNumber: '',

      // ログイン状態持続フラグ
      continuationFlg: true,
    };
  },
  async created() {
    // 本人確認項目保有チェック
    if (this.contractorConfirmData()) {
      this.$set(this, 'isConfirm', true);
    } else {
      await this.$router.push('/login');
    }
  },
  async mounted() {
    this.setAuthMessage();
    const agency = await getAgencies();
    // 代理店情報をセットする
    this.$set(this, 'agencyRecords', this.mapAgencyRecords(agency));
    this.$set(this, 'agencyPhoneNumber', agency.agencyPhoneNumber);
  },
  methods: {
    ...mapGetters('contractor', ['urlToken', 'accessToken']),
    ...mapGetters('temporarily', ['contractorConfirmData', 'sendData']),
    ...mapActions('contractor', ['setAccessToken', 'setRefreshToken']),
    ...mapActions('contract', ['setPendingStep', 'setIsProcedureSkipped']),
    ...mapActions('temporarily', ['setSendData']),

    async onAuth() {
      this.$set(this, 'isConnecting', true);

      if (await this.$refs.forms.validate()) {
        const response = await certificationsAuth(
          this.urlToken(),
          this.authCode,
          this.continuationFlg
        ).catch(err => {
          // ロックアウト時はロックアウト画面に遷移
          if (err.status === HttpStatus.Unauthorized) {
            this.$router.push('/login');
          } else if (err.status === HttpStatus.Locked) {
            this.$router.push('/lockout');
          } else {
            this.$set(this, 'isError', true);
          }
          return;
        });

        if (response) {
          this.setAccessToken(response.accessToken);
          if (this.continuationFlg) this.setRefreshToken(response.refreshToken);
          const contractStatus = await getStatus();
          if (contractStatus instanceof Error) {
            this.$router.replace('/Error');
            return;
          }
          const status = Number(contractStatus.status);
          if (status == ContractStatus.Waiting) {
            this.$router.push('/tos');
          } else {
            this.setPendingStep(completedStepNumber);
            this.setIsProcedureSkipped(true);
            this.$router.push({
              path: '/procedure_complete',
              query: { status: status },
            });
          }
        }
      }
      this.$set(this, 'isConnecting', false);
    },
    async backlAuth() {
      this.$router.push('/login');
    },
    async resendAuth() {
      this.$set(this, 'isConnecting', true);
      const response = await resendAuthCode(
        this.urlToken(),
        this.contractorConfirmData()
      ).catch(async err => {
        // 再送上限回数に到達している場合は問い合わせ先を表示
        if (err.status === HttpStatus.Unauthorized) {
          this.$set(
            this,
            'resndAuthCodeFailureMessage',
            this.$t('error.resndAuthCodeFailureLimitError')
          );
          this.$set(this, 'isAgency', true);
        } else {
          this.$set(
            this,
            'resndAuthCodeFailureMessage',
            this.$t('error.resndAuthCodeFailureError')
          );
        }
        this.$set(this, 'showResndAuthCodeFailureDialog', true);
        return;
      });

      if (response) {
        await this.setSendData(response);
        this.setAuthMessage();
        this.$set(this, 'showResndAuthCodeSuccessDialog', true);
      }
      this.$set(this, 'isConnecting', false);
    },
    closeResndAuthCodeSuccessDialog() {
      this.$set(this, 'showResndAuthCodeSuccessDialog', false);
    },
    closeResndAuthCodeFailureDialog() {
      this.$set(this, 'showResndAuthCodeFailureDialog', false);
    },
    setAuthMessage() {
      this.$set(
        this,
        'sendAuthMessage',
        this.sendData().sendResult
          ? this.$t('description.contractorSendAuthCode', {
              sendProtocol: ReverseSenderFlg[this.sendData().sendFlg],
            })
          : this.$t('description.contractorNotSendAuthCode', {
              time: AuthCodeExpiryTime,
              sendProtocol: ReverseSenderFlg[this.sendData().sendFlg],
            })
      );
      this.$set(
        this,
        'sendAuthCompleteMessage',
        this.sendData().sendResult
          ? this.$t('description.contractorSendAuthCodeComplete', {
              sendProtocol: ReverseSenderFlg[this.sendData().sendFlg],
            })
          : this.$t('description.contractorNotSendAuthCode', {
              time: AuthCodeExpiryTime,
              sendProtocol: ReverseSenderFlg[this.sendData().sendFlg],
            })
      );
    },
    // 代理店情報のマッピング
    mapAgencyRecords(agency) {
      // 表示項目
      const keys = ['name', 'staffName'];

      return keys.map(key => ({
        key: key,
        value: agency[key],
      }));
    },
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    onConvertToHalfWidth() {
      // 全角英数字を半角英数字に自動変換
      if (this.authCode)
        this.authCode = this.authCode.replace(/[０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
    },
  },
};
</script>
